import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate, Routes, Route } from "react-router-dom";
import axios from "../../../plugins/api";
import { URL } from "../../../constants/General";
import React, { lazy } from "react";

export const logIn = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    const response = await axios.post(URL + "/api/login_check", {
      username: credentials.email,
      password: credentials.password,
    });
    return response.data;
  }
);

const cleanUpAuthState = (state) => {
  state.loggedIn = false;
  state.token = null;
  state.user = null;
  state.userRole = null;
  state.avatar = null;
  state.company = null;

  localStorage.removeItem("access_token");
  localStorage.removeItem("user_role");
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    loggedIn: false,
    loginError: false,
    token: null,
    user: null,
    userRole: null,
    avatar: null,
    company: null,
    userId: null,
    companyMainUid: null,
    companyUid: null,
  },
  reducers: {
    logOut(state, action) {
      cleanUpAuthState(state);
    },
    pickRole(state, action) {
      state.userRole = action.payload;
      localStorage.setItem("user_role", action.payload);
    },
    clearRole(state) {
      state.userRole = null;
    },
    clearLoginError(state) {
      state.loginError = false;
    },
  },
  extraReducers: {
    [logIn.rejected]: (state, action) => {
      state.loading = false;
      state.loginError = true;
      cleanUpAuthState(state);
    },
    [logIn.fulfilled]: (state, action) => {
      state.loading = false;
      state.loggedIn = true;
      state.loginError = false;
      state.token = action.payload.token;

      if (action.payload.user !== undefined) {
        state.avatar = action.payload.user.avatar;
        state.company = action.payload.user.company;

        localStorage.setItem("companyUid", action.payload.user.companyUid)
        state.companyMainUid = action.payload.user.companyMainUid;
        state.companyUid = action.payload.user.companyUid;
        // state.userId = action.payload.user.person.id;
        if (action.payload.user.person !== null) {
          state.userId = action.payload.user.person.id;
        } else {
          state.userId = action.payload.user.user.id;
        }
      }
      // else {
      //   state.userId = action.payload.id;
      // }
      // if (
      //   action.payload.user !== undefined &&
      //   action.payload.user.person.id !== null
      // ) {
      //   state.userId = action.payload.user.person.id;
      // }
      state.userRole = action.payload.role;
      localStorage.setItem("user_role", action.payload.role);
      localStorage.setItem("access_token", state.token);
    },
    [logIn.pending]: (state, action) => {
      state.loading = true;
      state.loginError = false;
      cleanUpAuthState(state);
    },
  },
});

export const { logOut, pickRole, clearRole, clearLoginError } =
  authSlice.actions;

export default authSlice.reducer;
